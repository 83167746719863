import React, { useState, useEffect } from "react";
import deltie from "../../assets/images/deltie-good.png";
import xIcon from "../../assets/images/x-icon.svg";
import "../../styles/components/Layout/navbar.scss";

const DeltamathHomeBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const hiddenState = getCookie("home_banner_closed");
    if (!hiddenState) {
      setIsVisible(true);
    }
  }, []);

  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  };

  const handleClose = () => {
    setIsVisible(false);
    setCookie("home_banner_closed", "true", 31); // Cookie expires in 31 days
  };

  const getCookie = (name) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  if (!isVisible) {
    return <></>;
  }

  return (
    <div className="deltamath_home_banner introducing-home-experiment-flex">
      <div className="deltamath_home_banner_content">
        <div className="deltamath_home_banner_text">
          <img src={deltie} alt="Deltie deltamath home" />
          <p>
            New: Introducing DeltaMath for Home! Boost your math skills with a
            7-day FREE trial.{" "}
            <a
              href="/home"
              className="deltamath_home_banner_link"
              target="_blank"
            >
              Learn More.
            </a>
          </p>
        </div>
        <div className="deltamath_home_banner_close">
          <img src={xIcon} alt="close banner" onClick={handleClose} />
        </div>
      </div>
    </div>
  );
};

export default DeltamathHomeBanner;
