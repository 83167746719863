import React, { useRef, useState, useEffect } from "react";

export const GoogleSSO = ({ callBackFunction, buttonText = "signin_with", showOr = false }) => {
  const g_sso = useRef(null);
  const [signInError, setSignInError] = useState(null);

  useEffect(() => {
    if (g_sso.current) {
      if (
        typeof window === "undefined" ||
        !window.google ||
        g_sso.current === null
      ) {
        setSignInError(`Error initializing Google Sign-In!`);
        return;
      }
      setSignInError(null);

      try {
        window.google.accounts.id.initialize({
          client_id: process.env.GATSBY_GOOGLE_OAUTH_CLIENT_ID,
          callback: callBackFunction,
        });
        window.google.accounts.id.renderButton(g_sso.current, {
          theme: "outline",
          size: "large",
          width: "346",
          text: buttonText,
        });
      } catch (error) {
        setSignInError(`Error initializing Google Sign-In: ${error.message}`);
      }
    }
  }, [g_sso.current, buttonText]);

  return (
    <>
    {showOr && <div className="row center-word-or">or</div>}
      <div ref={g_sso} />{" "}
      {signInError ? (
        <div
          className="validation-alert msg-error"
          id="sign-in-with-google-error"
        >
          {signInError}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default GoogleSSO;
