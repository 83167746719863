import React from "react";
import { Dialog, DialogContent } from "@react-md/dialog";
import { Card, CardHeader, CardTitle, CardContent } from "@react-md/card";
import { Button } from "react-md";
import GoogleSSO from "../GoogleSSO";
import { actuallyLogin, getDeltaMathAPI } from "../../utilities/utilities";

// props: visible, enable, disable, selection, target (teacher or student)

function GoogleOrEmailSignUp(props) {
  const handleSignUpWithGoogle = async (googleData) => {
    if (googleData.error && googleData.error === "popup_closed_by_user") {
      props.disable();
    } else if (googleData.credential) {
      const res = await fetch(getDeltaMathAPI() + "/login_with_google", {
        method: "POST",
        body: JSON.stringify({ googleToken: googleData.credential }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();

      if (data.found === false) {
        // open teacher/student account creation selection modal
        props.selection({
          selection: "google",
          data: data.ticket.payload,
          target: props.target,
        });
      } else {
        // account exist with this google email
        data.signed_in_through_sign_up = true;
        actuallyLogin(data);
      }
      //close moodal
      props.disable();
    }
  };

  const handleDeltaMathSelected = () => {
    props.selection({ selection: "deltaMath", target: props.target });
    props.disable();
  };

  return (
    <Dialog
      id="choose-sign-up-type-dialog"
      visible={props.visible}
      onRequestClose={props.disable}
      aria-labelledby="dialog-title"
      className="choose-sign-up-type-dialog"
    >
      <span className="sr-only" id="dialog-title">
        Choose signup type dialog
      </span>
      <DialogContent className="choose-sign-up-type-form-content">
        <Card
          className="modalTeamCard"
          id="choose-sign-up-type-form-dialog-toggle"
          onClick={props.enable}
        >
          <CardHeader className="teamCard-text">
            <CardTitle className="teamCard-text-name">
              Create an account
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="sign-up-type-button-wrapper">
              <div className="row" id="sign-in-with-google">
                <GoogleSSO
                  callBackFunction={handleSignUpWithGoogle}
                  buttonText="signup_with"
                />
              </div>
              <div className="row center-word-or space-bottom">or</div>
              <Button
                className="sign-up-type-button"
                theme="primary"
                themeType="contained"
                onClick={handleDeltaMathSelected}
              >
                Sign up with DeltaMath
              </Button>
            </div>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
}

export default GoogleOrEmailSignUp;
