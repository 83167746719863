import React from "react";
import { MIN_PASSWORD_LENGTH } from "./ResetPassword/ResetPasswordForm";

const PasswordRequirementsMessage = ({ password, confirmPassword, passwordSecure}) => {
  return (
    <>
      {password.length >= MIN_PASSWORD_LENGTH && !passwordSecure && (
        <div className="validation-alert msg-error ">
          <p>
            {"Passwords must contain at least 3 of the following:"}

            <p>- Uppercase letter</p>
            <p>- Lowercase letter</p>
            <p>- Number</p>
            <p>- Special character</p>
          </p>
        </div>
      )}
      {password && password.length < MIN_PASSWORD_LENGTH && (
        <div className="validation-alert msg-error ">
          <p>
            {`Passwords must be at least ${MIN_PASSWORD_LENGTH} characters in length.`}
          </p>
        </div>
      )}
      {password.length >= MIN_PASSWORD_LENGTH &&
        confirmPassword.length > 0 &&
        password !== confirmPassword && (
          <div className="validation-alert msg-error ">
            <p>Passwords must match.</p>
          </div>
        )}
    </>
  );
};

export default PasswordRequirementsMessage;
