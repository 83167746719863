import React, { useEffect, useState } from "react";
import { Select, Checkbox, TextField, Button } from "react-md";
import "../../styles/components/teachers-schools/SchoolSelectModal.scss";
import { getDeltaMathAPI } from "../../utilities/utilities";
import { RiArrowDownSFill } from "@react-icons/all-files/ri/RiArrowDownSFill";
import {
  OUTSIDE_US_OPTIONS,
  US_STATES,
  US_STATES_CODES,
} from "../../constants/states";

const NonTeacherSelection = (props) => {
  const [userType, setUserType] = useState();
  const [country, setCountry] = React.useState("United States");
  const [city, setCity] = React.useState("");
  const [stateOrProvince, setStateOrProvince] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [district, setDistrict] = React.useState("");
  const [outsideUS, setOutsideUS] = useState(false);
  const [state, setState] = React.useState("");

  useEffect(() => {
    if (props.sendBackInfo) {
      props.sendBackInfo({
        city: city,
        state: stateOrProvince,
        country: country,
        zipCode: postalCode,
        userType: userType,
        schoolName: district,
      });
    }
  }, [
    userType,
    country,
    city,
    stateOrProvince,
    postalCode,
    district,
    outsideUS,
  ]);

  const outsideOfUSToggle = () => {
    const newValue = !outsideUS;
    setCountry("United States");
    setOutsideUS(newValue);
  };

  const submitInfo = () => {
    fetch(`${getDeltaMathAPI()}/teacher/account/update_school`, {
      body: JSON.stringify({
        city: city,
        state: stateOrProvince,
        country: country,
        zipCode: postalCode,
        userType: userType,
        schoolName: district,
      }),
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (props.setUpdateSubmitted) {
          props.setUpdateSubmitted(true);
        }
      });
  };

  return (
    <>
      <div className="non-teacher__container">
        <div
          className={`non-teacher__type ${
            userType === "Parent" ? "non-teacher__selected" : ""
          }`}
          onClick={() => setUserType("Parent")}
        >
          I'm a Parent/Learner
        </div>
        <div
          className={`non-teacher__type ${
            userType === "Tutor" ? "non-teacher__selected" : ""
          }`}
          onClick={() => setUserType("Tutor")}
        >
          I'm a Tutor
        </div>
        <div
          className={`non-teacher__type ${
            userType === "Staff" ? "non-teacher__selected" : ""
          }`}
          onClick={() => setUserType("Staff")}
        >
          I am District Staff
        </div>
      </div>

      {userType && (
        <>
          {userType === "Staff" && (
            <div id="school-row-id-7" className="ss-row">
              <div className="ss-row__col">
                <TextField
                  id="custom-district-text-field"
                  name="qrDistrictField"
                  label="District Name"
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                />
              </div>
            </div>
          )}
          <div id="school-row-id-4" className="ss-row">
            <div className="ss-row__col">
              <TextField
                id="custom-country-text-field"
                name="qrCountryField"
                label="Country"
                value={country}
                disabled={!outsideUS}
                onChange={(e) => setCountry(e.target.value)}
              />
            </div>

            <div id="custom-city-column" className="ss-row__col">
              <TextField
                id="custom-city-text-field"
                name="customCityTextField"
                label="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
          </div>
          <div id="school-row-id-5" className="ss-row">
            <div className="ss-row__col">
              {outsideUS ? (
                <TextField
                  id="custom-state-provice-text-field"
                  name="qrStateProvince"
                  label="State/Province"
                  value={stateOrProvince}
                  onChange={(e) => setStateOrProvince(e.target.value)}
                />
              ) : (
                <Select
                  id="custom-select-8"
                  label="State"
                  {...props}
                  options={US_STATES.filter(
                    (x) => !OUTSIDE_US_OPTIONS.some((y) => y === x),
                  )}
                  disabled={outsideUS}
                  value={state}
                  onChange={(e) => {
                    setState(e);
                    setStateOrProvince(
                      US_STATES_CODES[US_STATES.findIndex((x) => x === e) - 1],
                    );
                  }}
                  disableLeftAddon={false}
                  rightChildren={<RiArrowDownSFill className="dropDownArrow" />}
                />
              )}
              <Checkbox
                id="outside-us"
                name="outsideUS"
                className="outside_us_checkbox"
                label="I'm outside the US"
                value={outsideUS}
                onClick={outsideOfUSToggle}
              />
            </div>
            <div id="custom-postalCode-column" className="ss-row__col">
              <TextField
                id="postalCode-text-field"
                name="postalCodeTextField"
                label="Postal Code"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </div>
          </div>
        </>
      )}

      {!props.sendBackInfo && (
        <Button
          className="school-info-button"
          onClick={submitInfo}
          disabled={
            !stateOrProvince ||
            !userType ||
            !country ||
            !city ||
            !postalCode ||
            (userType === "Staff" && !district)
          }
        >
          Submit
        </Button>
      )}
    </>
  );
};

export default NonTeacherSelection;
