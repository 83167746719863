import React from "react";
import { Controller, useForm } from "react-hook-form";

import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import TimezonePicker from "react-bootstrap-timezone-picker";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";

import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import { CountryRegionData } from "react-country-region-selector";
import { Dialog, DialogContent, DialogFooter } from "@react-md/dialog";
import { VscChromeClose } from "@react-icons/all-files/vsc/VscChromeClose";
import { Card, CardHeader, CardTitle, CardContent } from "@react-md/card";
import { Select, TextField, Button } from "react-md";
import { RiArrowDownSFill } from "@react-icons/all-files/ri/RiArrowDownSFill";

import { TIMEZONES_FOR_PICKER } from "../../assets/constants/timezones";
import {
  US_STATES,
  US_STATES_CODES,
  OUTSIDE_US_OPTIONS,
} from "../../constants/states";
import {
  evaluatePassword,
  getDeltaMathAPI,
  getRecaptchaToken,
  loadRecaptchaScript,
} from "../../utilities/utilities";
import { MIN_PASSWORD_LENGTH } from "../ResetPassword/ResetPasswordForm";
import PasswordRequirementsMessage from "../PasswordRequirementsMessage";
import NonTeacherSelection from "./NonTeacherSelection";

const md5 = require("md5");

// IMPORTING CONSTANTS FROM components/utilities

function CreateTeacherForm(props) {
  const { accountData } = props;
  let props_email, given_name, family_name;
  if (accountData) {
    props_email = accountData.email || "";
    given_name = accountData.given_name || "";
    family_name = accountData.family_name || "";
  }
  const disablePasswordInput = !!accountData;
  const [createAccountSubmitted, setCreateAccountSubmitted] =
    React.useState(false);
  const {
    control,
    register,
    handleSubmit,
    errors,
    setError,
    reset,
    setValue,
    clearErrors,
    formState: { isSubmitting },
  } = useForm();

  const COUNTRIES = ["United States"];

  // FILLING ABOVE CONSTANTS WITH DATA AVAILABLE FROM react-country-region-selector PACKAGE
  CountryRegionData.map((data) => {
    if (data[0] !== "United States") {
      COUNTRIES.push(data[0]);
    }
  });

  const SALUTATION_ITEMS = [
    "Mr.",
    "Mrs.",
    "Ms.",
    "Dr.",
    "First Name",
    "Custom",
  ];

  const CLASS_GROUPING_ITEMS = ["Period", "Class", "Section", "Custom"];
  const NUMBER_OF_GROUPING_ITEMS = ["1", "2", "3", "4", "5", "6", "7", "8"];

  const [salutationValue, setSalutationValue] = React.useState("");
  const [customSalutation, setCustomSalutation] = React.useState("");
  const [salutation, setSalutation] = React.useState("");
  const [forceShowTimezone, setForceShowTimezone] = React.useState(false);

  // possibly provided by log in with google no account found response
  const [firstName, setFirstName] = React.useState(given_name);
  const [lastName, setLastName] = React.useState(family_name);
  const [previewName, setPreviewName] = React.useState(family_name);

  const propsEmail = props_email ? props_email : "";
  const [emailAddress, setEmailAddress] = React.useState(propsEmail);
  const [password, setPassword] = React.useState("");
  const [passwordSecure, setPasswordSecure] = React.useState(false);
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const [state, setState] = React.useState("");
  const [schoolOrDistrictName, setSchoolOrDistrictName] = React.useState({
    name: "",
  });
  const [school, setSchool] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [streetAddress, setStreetAddress] = React.useState("");
  const [city, setCity] = React.useState("");
  const [timezone, setTimezone] = React.useState("");
  const [stateOrProvince, setStateOrProvince] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [userType, setUserType] = React.useState("");
  const [diggerId, setDiggerId] = React.useState("");
  const [autocompleteValue, setAutocompleteValue] = React.useState("");
  const [submitInitiated, setSubmitInitiated] = React.useState(false);
  const [reCaptchaLoaded, setRecaptchaLoaded] = React.useState(false);
  const [schoolEmployee, setSchoolEmployee] = React.useState(true);
  const [teachercode, setTeachercode] = React.useState("");

  const handleSalutationChange = React.useCallback((nextValue, _option) => {
    setSalutationValue(nextValue);
    if (nextValue !== "Custom") setSalutation(nextValue);
  }, []);

  const handleStateChange = React.useCallback((nextValue, _option) => {
    setState(nextValue);
  }, []);

  const handleCountryChange = React.useCallback((nextValue, _option) => {
    setCountry(nextValue);
  }, []);

  const handleStateOrProvinceChange = React.useCallback(
    (nextValue, _option) => {
      setStateOrProvince(nextValue);
    },
    [],
  );

  const handleAutocompleteOnSelect = (item) => {
    setSchoolOrDistrictName(item);
  };

  React.useEffect(() => {
    if (salutationValue === "" && lastName !== "") setPreviewName(lastName);
    else if (salutationValue === "Custom") {
      setPreviewName(`${customSalutation} ${lastName}`);
      setSalutation(customSalutation);
    } else if (salutationValue === "First Name")
      setPreviewName(`${firstName} ${lastName}`);
    else setPreviewName(`${salutationValue} ${lastName}`);
  }, [salutationValue, customSalutation, firstName, lastName]);

  const getStateCode = () => {
    var i;
    US_STATES.forEach((currentState, index) => {
      if (currentState === state) {
        i = index - 1;
      }
    });
    return US_STATES_CODES[i] || "";
  };

  React.useEffect(() => {
    if (schoolOrDistrictName.name !== "") {
      if (schoolOrDistrictName.name.indexOf("not found") == -1) {
        setSchool(schoolOrDistrictName.name);
        setStreetAddress(schoolOrDistrictName.address.street);
        setCity(schoolOrDistrictName.address.city);
        setTimezone(schoolOrDistrictName.timezone);
        if (schoolOrDistrictName.timezone) { // Response had timezone, use that
          setForceShowTimezone(false);
        }
        setDiggerId(schoolOrDistrictName.id);
        if (schoolOrDistrictName.address) {
          setCountry("United States");
          setValue("countrySelectField", "United States");
        } else {
          setValue("countrySelectField", "");
        }
        setStateOrProvince(getStateCode());
        setValue("stateProvinceField", getStateCode());
        setPostalCode(schoolOrDistrictName.address.zip);
      } else if (schoolOrDistrictName.name.indexOf("not found") > -1) {
        setSchool("");
        setStreetAddress("");
        setCity("");
        setCountry("United States");
        setValue("countrySelectField", "United States");
        setStateOrProvince(getStateCode());
        setValue("stateProvinceField", getStateCode());
        setPostalCode("");
        setTimezone("");
        setForceShowTimezone(true);
        setDiggerId("");
      }
    }
  }, [schoolOrDistrictName]);

  React.useEffect(() => {
    setSchool("");
    setStreetAddress("");
    setCity("");
    setCountry("United States");
    setStateOrProvince(getStateCode());
    setPostalCode("");
    if (state !== "") {
      setForceShowTimezone(true);
    }
  }, [state]);

  // HANDLING TEACHER CREATE ACCOUNT FORM SUBMISSION
  const onTeacherAccountSubmit = async (data) => {
    if (submitInitiated) return; // don't want to create two

    if (
      !userType &&
      !schoolOrDistrictName.name &&
      OUTSIDE_US_OPTIONS.indexOf(state) === -1
    ) {
      setError("submit", {
        type: "manual",
        message: "You must fill out all of the required information.",
      });
      return;
    }

    if (!timezone) {
      setError("submit", {
        type: "manual",
        message: "You must select a timezone.",
      });
      return;
    }

    if (password !== confirmPassword) {
      setError("submit", {
        type: "manual",
        message: "Your two passwords do not match.",
      });
      return;
    }

    let salutationTemp;
    if (salutationValue === "Custom") salutationTemp = customSalutation;
    else if (salutationValue === "First Name") salutationTemp = "first";
    else salutationTemp = salutationValue;
    if (!salutationTemp) {
      setError("submit", {
        type: "manual",
        message: "If you have a custom salutation, it cannot be left blank.",
      });
      return;
    }

    if (!timezone || timezone === "") {
      setError("submit", {
        type: "manual",
        message: "Please select a timezone.",
      });
    }
    const token = await getRecaptchaToken("teacher_account_creation");

    try {
      // TODO - does this need to be updated to match the new sections pattern?
      //* send server call from here on form submission
      const parsedFirst = firstName !== "" ? firstName : given_name;
      const parsedLast = lastName !== "" ? lastName : family_name;
      const parsedEmail = emailAddress !== "" ? emailAddress : props_email;
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          account: {
            salutation: salutationTemp,
            first: parsedFirst,
            last: parsedLast,
            email: parsedEmail,
            password: md5("deltamath" + password),
            meets_criteria: passwordSecure,
            sections: {
              // set to empty so teacher can create sections themselves
              groupedby: "Class",
              sections: [],
            },
            schoolinfo: {
              name: school,
              city: city,
              state: stateOrProvince, // or state?
              zip: postalCode,
              userType: userType,
            },
            timezone: timezone,
          },
          teacher: true,
          googleSignup: !!accountData,
          reCaptchaToken: token,
        }),
      };

      setSubmitInitiated(true);

      fetch(getDeltaMathAPI() + "/create_account", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setSubmitInitiated(false);

          if (Array.isArray(data)) {
            // errors!
            setError("submit", {
              type: "manual",
              message: data,
            });
            return;
          }

          if (data && data.teachercode) setTeachercode(data.teachercode);

          setCreateAccountSubmitted(true);
          reset();
        });
    } catch (error) {
      // handle server errors
      setSubmitInitiated(false);
      setError("submit", {
        type: "manual",
        message: "Your account couldn't be created. Please try again.",
      });
    }
  };

  const showSubmitError = (msg) => {
    return (Array.isArray(msg.message) ? msg.message : [msg.message]).map(
      (message, idx) => (
        <div className="validation-alert msg-error" key={idx}>
          <p>{message}</p>
        </div>
      ),
    );
  };

  const showAccountSuccess = (
    <div className="msg-confirm">
      <h2 className="create-teacher-account-form-subHeader">Success</h2>
      <p>Your teacher account has been created successfully.</p>
      <br />
      <p>
        You may now sign in to create classes and assignments for your students.
      </p>
      <Button
        onClick={() => {
          setCreateAccountSubmitted(false);
          props.disableCreate();
        }}
        className="outline-primary-btn"
        theme="primary"
        themeType="outline"
      >
        Okay
      </Button>
    </div>
  );

  React.useEffect(() => {
    if (props.visibleCreate === false) {
      resetStates();
    } else if (!reCaptchaLoaded) {
      loadRecaptchaScript();
      setRecaptchaLoaded(true);
    }
  }, [props.visibleCreate]);

  const resetStates = () => {
    setSalutationValue("");
    setCustomSalutation("");
    setSalutation("");
    setFirstName("");
    setLastName("");
    setPreviewName("");
    setEmailAddress("");
    setPassword("");
    setConfirmPassword("");
    setState("");
    setSchoolOrDistrictName({ name: "" });
    setSchool("");
    setCountry("");
    setStreetAddress("");
    setCity("");
    setTimezone("");
    setStateOrProvince("");
    setPostalCode("");
  };

  const pluralize = (text) => {
    if (!text) return "";
    else if (text.charAt(text.length - 1) == "s") return text + "es";
    else return text + "s";
  };

  const clearGlobalError = () => {
    clearErrors("submit");
  };
  const [isLoading, setIsLoading] = React.useState(false);
  const [schoolOptions, setSchoolOptions] = React.useState([]);
  const handleSearch = (query) => {
    setIsLoading(true);
    fetch(
      getDeltaMathAPI() +
        `/digger_autocomplete?type=schools&state=${stateOrProvince}&search=${query}`,
    )
      .then((resp) => resp.json())
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: i.name,
          address: i.address,
          timezone: i.timezone,
        }));

        options.unshift({
          id: -1,
          name: "School not found?",
          address: { street: "" },
          timezone: "",
        });
        setSchoolOptions(options);
        setIsLoading(false);
      });
  };
  const filterBy = () => true;

  return (
    <>
      {createAccountSubmitted ? (
        <>
          <Dialog
            id="teacher-success-dialog"
            aria-labelledby="dialog-title"
            className="teacherFormDialog form-dialog"
            visible={props.visibleCreate}
            onRequestClose={props.disableCreate}
          >
            <span className="sr-only" id="dialog-title">
              Account creation succeeded
            </span>
            <DialogContent className="teacher-form-content teacher-success-content">
              <Card className="modalTeamCard" id="teacher-form-dialog-toggle">
                <CardHeader className="teamCard-text">
                  <CardTitle className="teamCard-text-name">
                    Create Teacher Account
                  </CardTitle>
                </CardHeader>
                <CardContent>{showAccountSuccess}</CardContent>
              </Card>
            </DialogContent>
          </Dialog>
        </>
      ) : (
        <Dialog
          id="teacher-form-dialog"
          visible={props.visibleCreate}
          onRequestClose={props.disableCreate}
          aria-labelledby="dialog-title"
          className="teacherFormDialog form-dialog"
        >
          <span className="sr-only" id="dialog-title">
            Create teach account dialog
          </span>
          <DialogFooter>
            <VscChromeClose
              className="dialog-close1"
              id="dialog-close"
              onClick={props.disableCreate}
            />
          </DialogFooter>
          <DialogContent className="teacher-form-content">
            <Card
              className="modalTeamCard"
              id="teacher-form-dialog-toggle"
              onClick={props.enableCreate}
            >
              <CardHeader className="teamCard-text">
                <CardTitle className="teamCard-text-name">
                  Create Teacher Account
                </CardTitle>
              </CardHeader>
              <CardContent>
                <form
                  className="popup-form create-teacher-account-form container"
                  onSubmit={handleSubmit(onTeacherAccountSubmit)}
                >
                  {/* PERSONAL INFORMATION */}
                  <h2 className="create-teacher-account-form-subHeader">
                    Personal Information
                  </h2>
                  <div id="personal-row-id" className="row">
                    <div className="column">
                      <Controller
                        control={control}
                        name="salutation"
                        defaultValue=""
                        rules={{ required: "Salutation is required" }}
                        disabled={isSubmitting}
                        render={(props) => {
                          return (
                            <Select
                              id="salutation"
                              {...props}
                              label="Salutation"
                              options={SALUTATION_ITEMS}
                              value={salutationValue}
                              onChange={(e) => {
                                props.onChange(e);
                                handleSalutationChange(e);
                              }}
                              disableLeftAddon={false}
                              rightChildren={
                                <RiArrowDownSFill className="dropDownArrow" />
                              }
                            />
                          );
                        }}
                      />
                    </div>
                    {salutationValue === "Custom" ? (
                      <div id="custom-salutation-column" className="column">
                        <TextField
                          id="custom-salutation-text-field"
                          name="customSalutationTextField"
                          label="Custom"
                          onChange={(e) => setCustomSalutation(e.target.value)}
                          ref={register({
                            required: "Custom salutation is required",
                          })}
                          disabled={isSubmitting}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div id="personal-row-id" className="row">
                    <div className="column">
                      <TextField
                        id="first-name-text-field"
                        name="firstNameTextField"
                        label="First Name"
                        defaultValue={given_name}
                        onChange={(e) => {
                          setFirstName(e.target.value);
                        }}
                        ref={register({ required: "First name is required" })}
                        disabled={isSubmitting}
                      />
                    </div>
                    <div className="column">
                      <TextField
                        id="last-name-text-field"
                        name="lastNameTextField"
                        label="Last Name"
                        defaultValue={family_name}
                        onChange={(e) => setLastName(e.target.value)}
                        ref={register({ required: "Last name is required" })}
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>

                  <label className="previewNameContainer">
                    Name Preview:&nbsp;
                    <span className="previewName">{previewName}</span>
                  </label>

                  {(!!accountData && salutation !== "") ||
                  (salutation !== "" && firstName !== "" && lastName !== "") ? (
                    <>
                      <h2 className="create-teacher-account-form-subHeader">
                        Login Information
                      </h2>

                      <div id="login-row-id" className="row">
                        <div className="emailColumn">
                          <TextField
                            id="email-address-text-field"
                            name="emailAddressTextField"
                            label="Email Address (Username)"
                            defaultValue={props_email}
                            onChange={(e) => setEmailAddress(e.target.value)}
                            type="email"
                            ref={register({
                              required: "Please enter a valid email address.",
                            })}
                            disabled={isSubmitting || !!accountData}
                          />
                        </div>
                      </div>

                      <div id="login-row-id" className="row">
                        {disablePasswordInput ? (
                          <>
                            <div className="googlePasswordHint">
                              Accounts created with "Sign up with Google" do not
                              need a password.
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="column">
                              <TextField
                                id="password-text-field"
                                name="passwordTextField"
                                label="Password"
                                onChange={(e) => {
                                  setPassword(e.target.value);
                                  setPasswordSecure(
                                    evaluatePassword(e.target.value),
                                  );
                                }}
                                type="password"
                                ref={register({
                                  required: "Password is required",
                                })}
                                disabled={isSubmitting}
                              />
                            </div>
                            <div className="column">
                              <TextField
                                id="confirm-password-text-field"
                                name="confirmPasswordTextField"
                                label="Confirm Password"
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                                type="password"
                                ref={register({
                                  required: "Confirm password is required",
                                })}
                                disabled={isSubmitting}
                              />
                            </div>
                          </>
                        )}
                      </div>
                      {(emailAddress !== "" &&
                        password !== "" &&
                        confirmPassword !== "" &&
                        (password.length >= MIN_PASSWORD_LENGTH ||
                          confirmPassword.length >= MIN_PASSWORD_LENGTH) &&
                        passwordSecure &&
                        password === confirmPassword) ||
                      !!accountData ? (
                        <>
                          {!schoolEmployee ? (
                            <>
                              <a
                                className="create-teacher-account__not_school"
                                onClick={() => {
                                  setTimezone("");
                                  setSchoolEmployee(!schoolEmployee);
                                  setForceShowTimezone(false);
                                }}
                              >
                                School Based?
                              </a>
                              <NonTeacherSelection
                                sendBackInfo={(nonTeacherData) => {
                                  setSchool(nonTeacherData.schoolName);
                                  setCity(nonTeacherData.city);
                                  setStateOrProvince(nonTeacherData.state);
                                  setCountry(nonTeacherData.country);
                                  setPostalCode(nonTeacherData.zipCode);
                                  setUserType(nonTeacherData.userType);
                                  setForceShowTimezone(!!nonTeacherData.userType); // only show timezone once a selection is made, for UI cleanliness
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <a
                                className="create-teacher-account__not_school"
                                onClick={() => {
                                  setTimezone("");
                                  setSchoolEmployee(!schoolEmployee);
                                }}
                              >
                                Not School Based?
                              </a>
                              <h2 className="create-teacher-account-form-subHeader">
                                School Information
                              </h2>

                              <div id="school-row-id-1" className="row">
                                <div className="column">
                                  <Controller
                                    control={control}
                                    name="stateSelectField"
                                    defaultValue=""
                                    rules={
                                      schoolEmployee && {
                                        required: "State is required.",
                                      }
                                    }
                                    disabled={isSubmitting}
                                    render={(props) => (
                                      <Select
                                        id="custom-select-4"
                                        label="State"
                                        {...props}
                                        options={US_STATES}
                                        value={state}
                                        onChange={(e) => {
                                          props.onChange(e);
                                          if (e) handleStateChange(e);
                                        }}
                                        disableLeftAddon={false}
                                        rightChildren={
                                          <RiArrowDownSFill className="dropDownArrow" />
                                        }
                                      />
                                    )}
                                  />
                                </div>
                                {OUTSIDE_US_OPTIONS.indexOf(state) === -1 &&
                                state !== "" ? (
                                  <div
                                    id="schoolOrDistrictName-column"
                                    className="column"
                                  >
                                    <AsyncTypeahead
                                      id="crSchoolOrDistrictName-text-field"
                                      labelKey="name"
                                      inputProps={{
                                        name: "autocomplete-school-or-district",
                                        value: autocompleteValue,
                                      }}
                                      minLength={4}
                                      isLoading={isLoading && false}
                                      onChange={(text, e) => {
                                        if (text.length > 0)
                                          handleAutocompleteOnSelect(text[0]);
                                      }}
                                      filterBy={filterBy}
                                      onSearch={handleSearch}
                                      options={schoolOptions}
                                      placeholder="Search school here..."
                                    />

                                    {school === "" && false /* not needed */ ? (
                                      <p
                                        style={{
                                          padding: "5px",
                                          fontSize: "0.9rem",
                                        }}
                                      >
                                        Don't find your school? Select{" "}
                                        <i>"School not found?</i>" from the
                                        search.
                                      </p>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                              {state !== "" ? (
                                <>
                                  <div id="school-row-id-2" className="row">
                                    <div
                                      id="schoolName-column"
                                      className="schoolColumn"
                                    >
                                      <TextField
                                        id="schoolNametextfield"
                                        name="schoolNametextfield"
                                        label="School Name"
                                        value={school}
                                        onChange={(e) =>
                                          setSchool(e.target.value)
                                        }
                                        ref={register(
                                          schoolEmployee && {
                                            required:
                                              "Please enter a school name",
                                          },
                                        )}
                                        disabled={
                                          (isSubmitting ||
                                            OUTSIDE_US_OPTIONS.indexOf(
                                              state,
                                            ) === -1) &&
                                          schoolOrDistrictName.name.indexOf(
                                            "not found",
                                          ) == -1
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div id="school-row-id-7" className="row">
                                    <div
                                      id="schoolAddress-column"
                                      className="streetAddressColumn"
                                    >
                                      <TextField
                                        id="streetAddressTextfield"
                                        name="streetAddressTextfield"
                                        label="Street Address"
                                        value={streetAddress}
                                        onChange={(e) =>
                                          setStreetAddress(e.target.value)
                                        }
                                        ref={
                                          schoolEmployee &&
                                          register({
                                            required:
                                              "Please enter the address.",
                                          })
                                        }
                                        disabled={
                                          (isSubmitting ||
                                            OUTSIDE_US_OPTIONS.indexOf(
                                              state,
                                            ) === -1) &&
                                          schoolOrDistrictName.name.indexOf(
                                            "not found",
                                          ) == -1
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div id="school-row-id-4" className="row">
                                    <div className="column">
                                      <Controller
                                        control={control}
                                        name="countrySelectField"
                                        defaultValue={country}
                                        rules={
                                          schoolEmployee && {
                                            required:
                                              "Please select a country.",
                                          }
                                        }
                                        disabled={
                                          isSubmitting ||
                                          OUTSIDE_US_OPTIONS.indexOf(state) ===
                                            -1
                                        }
                                        render={(props) => (
                                          <Select
                                            id="custom-select-5"
                                            label="Country"
                                            {...props}
                                            options={COUNTRIES}
                                            value={country}
                                            onChange={(e) => {
                                              props.onChange(e);
                                              handleCountryChange(e);
                                            }}
                                            disableLeftAddon={false}
                                            disabled={
                                              isSubmitting ||
                                              OUTSIDE_US_OPTIONS.indexOf(
                                                state,
                                              ) === -1
                                            }
                                            rightChildren={
                                              <RiArrowDownSFill className="dropDownArrow" />
                                            }
                                          />
                                        )}
                                      />
                                    </div>

                                    <div
                                      id="custom-city-column"
                                      className="column"
                                    >
                                      <TextField
                                        id="cityTextField"
                                        name="cityTextField"
                                        label="City"
                                        value={city}
                                        ref={
                                          schoolEmployee &&
                                          register({
                                            required: "Please enter the city",
                                          })
                                        }
                                        onChange={(e) =>
                                          setCity(e.target.value)
                                        }
                                        disabled={
                                          (isSubmitting ||
                                            OUTSIDE_US_OPTIONS.indexOf(
                                              state,
                                            ) === -1) &&
                                          schoolOrDistrictName.name.indexOf(
                                            "not found",
                                          ) == -1
                                        }
                                      />
                                    </div>
                                  </div>
                                  {country === "United States" ? (
                                    <div id="school-row-id-6" className="row">
                                      <div className="column">
                                        <Controller
                                          control={control}
                                          name="stateProvinceField"
                                          defaultValue={stateOrProvince}
                                          rules={
                                            schoolEmployee && {
                                              required:
                                                "Please select a state or province.",
                                            }
                                          }
                                          disabled={
                                            isSubmitting ||
                                            OUTSIDE_US_OPTIONS.indexOf(
                                              state,
                                            ) === -1
                                          }
                                          render={(props) => (
                                            <Select
                                              id="custom-select-6"
                                              label="State/Province"
                                              {...props}
                                              options={US_STATES_CODES}
                                              value={stateOrProvince}
                                              disabled={
                                                isSubmitting ||
                                                OUTSIDE_US_OPTIONS.indexOf(
                                                  state,
                                                ) === -1
                                              }
                                              onChange={(e) => {
                                                props.onChange(e);
                                                handleStateOrProvinceChange(e);
                                              }}
                                              disableLeftAddon={false}
                                              rightChildren={
                                                <RiArrowDownSFill className="dropDownArrow" />
                                              }
                                            />
                                          )}
                                        />
                                      </div>

                                      <div
                                        id="custom-postalCode-column"
                                        className="column"
                                      >
                                        <TextField
                                          id="postalCode-text-field"
                                          name="postalCodeTextField"
                                          label="Postal Code"
                                          value={postalCode}
                                          onChange={(e) =>
                                            setPostalCode(e.target.value)
                                          }
                                          ref={
                                            schoolEmployee &&
                                            register(
                                              schoolEmployee && {
                                                required:
                                                  "Please enter the postal code",
                                              },
                                            )
                                          }
                                          disabled={
                                            (isSubmitting ||
                                              OUTSIDE_US_OPTIONS.indexOf(
                                                state,
                                              ) === -1) &&
                                            schoolOrDistrictName.name.indexOf(
                                              "not found",
                                            ) == -1
                                          }
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                          {forceShowTimezone ? (
                            <div id="school-row-id-5" className="row">
                              <TimezonePicker
                                id="timezone-picker-id"
                                name="timezonePickerId"
                                absolute={true}
                                timezones={TIMEZONES_FOR_PICKER}
                                placeholder="Select timezone..."
                                value={timezone}
                                onChange={(e) => {
                                  setTimezone(e);
                                }}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  <div className="row error-row">
                    {errors.salutation && (
                      <div className="validation-alert msg-error ">
                        <p>{errors.salutation.message}</p>
                      </div>
                    )}
                    {errors.firstName && (
                      <div className="validation-alert msg-error ">
                        <p>{errors.firstName.message}</p>
                      </div>
                    )}
                    {errors.customSalutationTextField && (
                      <div className="validation-alert msg-error ">
                        <p>{errors.customSalutationTextField.message}</p>
                      </div>
                    )}
                    {errors.firstNameTextField && (
                      <div className="validation-alert msg-error ">
                        <p>{errors.firstNameTextField.message}</p>
                      </div>
                    )}
                    {errors.lastNameTextField && (
                      <div className="validation-alert msg-error ">
                        <p>{errors.lastNameTextField.message}</p>
                      </div>
                    )}
                    {errors.emailAddressTextField && (
                      <div className="validation-alert msg-error ">
                        <p>{errors.emailAddressTextField.message}</p>
                      </div>
                    )}
                    {errors.passwordTextField && (
                      <div className="validation-alert msg-error ">
                        <p>{errors.passwordTextField.message}</p>
                      </div>
                    )}
                    {errors.confirmPasswordTextField && (
                      <div className="validation-alert msg-error ">
                        <p>{errors.confirmPasswordTextField.message}</p>
                      </div>
                    )}
                    <PasswordRequirementsMessage
                      password={password}
                      confirmPassword={confirmPassword}
                      passwordSecure={passwordSecure}
                    />
                    {errors.cityTextField && (
                      <div className="validation-alert msg-error ">
                        <p>{errors.cityTextField.message}</p>
                      </div>
                    )}
                    {errors.stateSelectField && (
                      <div className="validation-alert msg-error ">
                        <p>{errors.stateSelectField.message}</p>
                      </div>
                    )}
                    {errors.schoolOrDistrictNameTextField && (
                      <div className="validation-alert msg-error ">
                        <p>{errors.schoolOrDistrictNameTextField.message}</p>
                      </div>
                    )}
                    {errors.schoolNametextfield && (
                      <div className="validation-alert msg-error ">
                        <p>{errors.schoolNametextfield.message}</p>
                      </div>
                    )}
                    {errors.streetAddressTextfield && (
                      <div className="validation-alert msg-error ">
                        <p>{errors.streetAddressTextfield.message}</p>
                      </div>
                    )}
                    {errors.countrySelectField && (
                      <div className="validation-alert msg-error ">
                        <p>{errors.countrySelectField.message}</p>
                      </div>
                    )}
                    {errors.stateProvinceField && (
                      <div className="validation-alert msg-error ">
                        <p>{errors.stateProvinceField.message}</p>
                      </div>
                    )}
                    {errors.postalCodeTextField && (
                      <div className="validation-alert msg-error ">
                        <p>{errors.postalCodeTextField.message}</p>
                      </div>
                    )}
                    {errors && errors.submit && showSubmitError(errors.submit)}
                  </div>
                  <div className="row">
                    <Button
                      className="form-submit-btn"
                      onClick={clearGlobalError}
                      themeType="contained"
                      type="submit"
                      disabled={
                        !accountData &&
                        (password.length < MIN_PASSWORD_LENGTH ||
                          !passwordSecure ||
                          password !== confirmPassword)
                      }
                    >
                      {submitInitiated
                        ? "submitting..."
                        : "Create Teacher Account"}
                    </Button>
                  </div>
                  <div className="row">
                    <p className="infoContainer">
                      By signing up, you are agreeing to the{" "}
                      <a className="infoLink" href="/terms-of-service">
                        Terms of Service
                      </a>
                    </p>
                  </div>
                </form>
              </CardContent>
            </Card>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default CreateTeacherForm;
