import React from "react";
import { Dialog, DialogContent, DialogFooter } from "@react-md/dialog";
import { VscChromeClose } from "@react-icons/all-files/vsc/VscChromeClose";
import { Card, CardHeader, CardTitle, CardContent } from "@react-md/card";
import { TextField, Button, Select } from "react-md";
import { useForm } from "react-hook-form";
import {
  actuallyLogin,
  handlePrelogin,
  processPreloginFactor,
  sendSlackLog,
  getRecaptchaToken,
  loadRecaptchaScript,
  getDeltaMathAPI,
  evaluatePassword,
} from "../../utilities/utilities";
import { MIN_PASSWORD_LENGTH } from "../ResetPassword/ResetPasswordForm";
import PasswordRequirementsMessage from "../PasswordRequirementsMessage";
import GoogleSSO from "../GoogleSSO";
const md5 = require("md5");

function CreateStudentForm(props) {
  const { accountData, code } = props;
  let props_email,
    given_name,
    family_name,
    parsedCode = "";
  if (code) parsedCode = code;
  if (accountData) {
    props_email = accountData.email || "";
    given_name = accountData.given_name || "";
    family_name = accountData.family_name || "";
  }
  const [submitted, setSubmitted] = React.useState(false);
  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    reset,
    formState: { isSubmitting },
  } = useForm();

  const [sectionOptions, setSectionOptions] = React.useState([]);
  const [sectionsObj, setSectionsObj] = React.useState({});
  const [teacherCode, setTeacherCode] = React.useState(parsedCode);
  const [studentIdRequired, setStudentIdRequired] = React.useState(false);
  const [studentEmailRequired, setStudentEmailRequired] = React.useState(false);
  const [teacherName, setTeacherName] = React.useState("");
  const [selectedClass, setSelectedClass] = React.useState("");
  const [firstName, setFirstName] = React.useState(given_name);
  const [lastName, setLastName] = React.useState(family_name);
  const [studentId, setStudentId] = React.useState("");
  const [email, setEmail] = React.useState(props_email);
  const [verifyEmail, setVerifyEmail] = React.useState(props_email);
  const [confirmEmail, setConfirmEmail] = React.useState(props_email);
  const [password, setPassword] = React.useState("");
  const [passwordSecure, setPasswordSecure] = React.useState(false);
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [submittingNow, setSubmittingNow] = React.useState(false);
  const [showSectionsSelect, setShowSectionsSelect] = React.useState(false);
  const [emailChecked, setEmailChecked] = React.useState(false);
  const [emailAvailable, setEmailAvailable] = React.useState(false);
  const [invalidAccountType, setInvalidAccountType] = React.useState(false);
  const [registerWithEmail, setRegisterWithEmail] = React.useState(false);
  const [sectionName, setSectionName] = React.useState("");
  const [reCaptchaLoaded, setRecaptchaLoaded] = React.useState(false);
  const [googleAccountData, setGoogleAccountData] = React.useState(accountData);

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
  
  const signInAndRegister = async () => {
    if (email && password && !submittingNow) {
      // prevent auto-sumbmission of form without form data
      try {
        setSubmittingNow(true);
        let responseJSON = await handlePrelogin(email);
        let requestBody = {
          email,
          registerSection: selectedClass,
          password: md5("deltamath" + password),
          meets_criteria: passwordSecure,
          prelogin_factor: processPreloginFactor(responseJSON.number),
          prelogin_token: responseJSON.token,
        };
        if (
          typeof window !== "undefined" &&
          localStorage.getItem("customer_service_token")
        ) {
          // window type check needed because in build process there is no window defined
          requestBody.customer_service_token = localStorage.getItem(
            "customer_service_token"
          ); // see https://github.com/gatsbyjs/gatsby/issues/14480 for more information
        }

        let requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
          credentials: "include",
        };

        // log in for real
        let response = await fetch(
          getDeltaMathAPI() + "/login",
          requestOptions
        ).catch((err) => {
          throw err;
        });
        if (!response.ok) {
          throw Error(response.status);
        }
        responseJSON = await response.json();
        if (responseJSON.message) {
          setError("submit", {
            type: "manual",
            message: responseJSON.message,
          });
          return;
        }

        actuallyLogin(responseJSON);
        setSubmittingNow(false);
      } catch (error) {
        setError("submit", {
          type: "manual",
          message: "Login failed. Please try again.",
        });
        setSubmittingNow(false);
      }
    }
  };

  const setGoogleFormData = (data) => {
    const { email, given_name, family_name } = data;
    setFirstName(given_name);
    setLastName(family_name);
    setEmail(email);
    setVerifyEmail(email);
    setConfirmEmail(email);
    setEmailChecked(true);
    setEmailAvailable(true);
    setGoogleAccountData(data);
    setRegisterWithEmail(true);
    setSubmittingNow(false);
  };

  const handleSignUpWithGoogle = async (googleData) => {
    if (googleData.error && googleData.error === "popup_closed_by_user") {
      // props.disable();
    } else {
      if (googleData.credential && !submittingNow) {
        // tokenId does not exist if google sign in auto-calls on component instantiation
        setSubmittingNow(true);
        const res = await fetch(getDeltaMathAPI() + "/login_with_google", {
          method: "POST",
          body: JSON.stringify({
            googleToken: googleData.credential,
            registerSection: selectedClass,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await res.json();

        if (data.found === false) {
          if (data.message) {
            setError("accountType", {
              type: "manual",
              message: data.message,
            });
            setSubmittingNow(false);
          } else {
            // redirect user to student account creation with pre-filled form
            const payload = data.ticket.payload;
            setGoogleFormData(payload);
          }
        } else {
          // account exist with this google email
          actuallyLogin(data);
          setSubmittingNow(false);
        }
      }
    }
  };

  // HANDLING FORM SUBMISSION
  const onSubmit = async (data) => {
    if (submittingNow) return;
    setSubmittingNow(true);
    await checkEmail(); // make sure user didn't change email address before submit
    if (emailAvailable) {
      if (password !== confirmPassword) {
        setError("submit", {
          type: "manual",
          message: "Your two passwords do not match.",
        });
        return;
      }

      if (email !== confirmEmail && !studentIdRequired) {
        setError("submit", {
          type: "manual",
          message:
            "Your two " +
            (studentEmailRequired ? "email addresses" : "usernames") +
            " do not match.",
        });
        return;
      }

      try {
        const parsedFirst = firstName !== "" ? firstName : given_name;
        const parsedLast = lastName !== "" ? lastName : family_name;
        const parsedEmail = email !== "" ? email : props_email;
        const selectedSection = sectionsObj[selectedClass]
          ? sectionsObj[selectedClass]
          : null;
        if (!selectedSection) {
          setSubmittingNow(false);
          return;
        }
        const token = await getRecaptchaToken("student_account_creation");
        //* send server call from here on form submission
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            account: {
              first: parsedFirst.slice(0, 100),
              last: parsedLast.slice(0, 100),
              login: parsedEmail,
              password: md5("deltamath" + password),
              meets_criteria: passwordSecure,
              teachercode: selectedSection.teachers[0],
              courseCode: selectedSection.code,
              student_id: studentId,
              sections: [selectedSection.name],
            },
            teacher: false,
            googleSignup: !!googleAccountData,
            reCaptchaToken: token,
          }),
        };

        fetch(getDeltaMathAPI() + "/create_account", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            setSubmittingNow(false);
            if (!data.success) {
              // errors!
              setError("submit", {
                type: "manual",
                message: data.message,
              });
              return;
            }
            setSubmitted(true);
            reset();
          });
      } catch (error) {
        // handle server errors
        setSubmittingNow(false);
        setError("submit", {
          type: "manual",
          message: "Your account couldn't be created. Please try again.",
        });
      }
    } else {
      // user email is already in db, try to sign in and register
      try {
        const parsedEmail = email !== "" ? email : props_email;
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: parsedEmail,
            password: md5("deltamath" + password),
            sectionId: selectedClass,
          }),
        };
        fetch(getDeltaMathAPI() + "/section_registration", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            setSubmittingNow(false);
            if (!data.success) {
              // errors!
              setError("submit", {
                type: "manual",
                message: data.message,
              });
              return;
            }
            setSubmitted(true);
            reset();
          });
      } catch (error) {
        // handle server errors
        setSubmittingNow(false);
        setError("submit", {
          type: "manual",
          message: "Your registration was unsuccessful. Please try again.",
        });
      }
    }
  };

  const showSubmitError = (msg) => {
    return (
      <div className="validation-alert msg-error">
        <p>{msg.message}</p>
      </div>
    );
  };

  const showAccountSuccess = (
    <div className="msg-confirm">
      <h2 className="subtitle">Success</h2>
      <p>
        Your account has been created successfully. Please log in to access your
        new course!
      </p>
      {/* <Link to="/"> */}
      <Button
        onClick={() => {
          setSubmitted(false);
          props.disable();
        }}
        className="outline-primary-btn"
        theme="primary"
        themeType="outline"
      >
        Okay
      </Button>
      {/* </Link> */}
    </div>
  );

  const selectSection = (s) => {
    const obj = sectionsObj[s];
    setSelectedClass(s);
    setTeacherName(obj.teacherShow);
    setStudentEmailRequired(obj.student_email_required);
    setStudentIdRequired(obj.student_id_required);
  };

  const checkTeacherCode = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        teachercode: teacherCode.trim().split(" ").join("").toUpperCase(),
      }),
    };
    let response;
    let resp;
    let respText;
    try {
      response = await fetch(
        getDeltaMathAPI() + "/get_teacher_info",
        requestOptions
      );
      // window.responseObj = response;
      respText = await response.text();
      resp = JSON.parse(respText);
    } catch (err) {
      setTeacherName("");
      setError("teachercode", {
        type: "manual",
        message:
          "There has been an error. Please delete the code and try again. If this message keeps appearing, try a different device for registering.",
      });
      if (response && response.status == 520) return; // known error, don't log stuff, just have them try again
      const myRandom = Math.floor(Math.random() * 9000000) + 1000000;
      let successSecondTry = false;
      try {
        sendSlackLog(
          "error in call to get_teacher_info (URL - " + myRandom + ")",
          { url: window.location },
          "Gatsby student registration form - #checkTeacherCode function"
        );
        // This was always false
        // if (process.env.GATSBY_API_URL.indexOf("www.deltamath.com") > -1) {
        //   // try a relative path
        //   try {
        //     response = await fetch("../api/get_teacher_info", requestOptions);
        //     respText = await response.text();
        //     resp = JSON.parse(respText);
        //     successSecondTry = true;
        //     sendSlackLog(
        //       "error in call to get_teacher_info (SUCCESS NOW! - " +
        //         myRandom +
        //         ")",
        //       { resp },
        //       "Gatsby student registration form - #checkTeacherCode function"
        //     );
        //   } catch (err) {
        //     sendSlackLog(
        //       "error in call to get_teacher_info (FAIL AGAIN! - " +
        //         myRandom +
        //         ")",
        //       {
        //         fetchurl: getDeltaMathAPI() + "/get_teacher_info",
        //       },
        //       "Gatsby student registration form - #checkTeacherCode function"
        //     );
        //   }
        // }
      } catch (e) {
        // do nothing
      }
      if (!successSecondTry) {
        try {
          sendSlackLog(
            "error in call to get_teacher_info (error - " + myRandom + ")",
            { error: err.toString() },
            "Gatsby student registration form - #checkTeacherCode function"
          );
        } catch (e) {
          // do nothing
        }
        try {
          sendSlackLog(
            "error in call to get_teacher_info (userAgent - " + myRandom + ")",
            { userAgent: navigator.userAgent },
            "Gatsby student registration form - #checkTeacherCode function"
          );
        } catch (e) {
          // do nothing
        }
        try {
          sendSlackLog(
            "error in call to get_teacher_info (resp - " + myRandom + ")",
            { resp },
            "Gatsby student registration form - #checkTeacherCode function"
          );
        } catch (e) {
          // do nothing
        }
        try {
          sendSlackLog(
            "error in call to get_teacher_info (status - " + myRandom + ")",
            { status: response.status },
            "Gatsby student registration form - #checkTeacherCode function"
          );
        } catch (e) {
          // do nothing
        }
        try {
          sendSlackLog(
            "error in call to get_teacher_info (respText short - " +
              myRandom +
              ")",
            { respText: respText.substr(0, 1000) },
            "Gatsby student registration form - #checkTeacherCode function"
          );
        } catch (e) {
          // do nothing
        }

        return;
      }
    }
    try {
      const respIsArray = Array.isArray(resp);
      if (resp.msg) {
        setTeacherName("");
        setError("teachercode", {
          type: "manual",
          message:
            resp.msg === "used"
              ? "This access code has already been used."
              : "The provided code is invalid.",
        });
      } else if (respIsArray && resp.length === 0) {
        setTeacherName("");
        setError("teachercode", {
          type: "manual",
          message:
            "There are no classes allowing enrollment for the provided teacher code.",
        });
      } else {
        // parse different response shapes for teachercode (ISection[]) vs course code (ISection)
        if (respIsArray) {
          const parsedOptions = resp.map((section) => {
            return { label: section.name, value: section._id };
          });
          const sectObj = resp.reduce((acc, section) => {
            acc[section._id] = section;
            return acc;
          }, {});
          setSectionOptions(parsedOptions);
          setSectionsObj(sectObj);
          setShowSectionsSelect(true);
        } else if (resp.locked) {
          setTeacherName("");
          setError("teachercode", {
            type: "manual",
            message: "Your teacher has locked enrollment for this class.",
          });
        } else {
          const obj = [resp].reduce((acc, section) => {
            acc[section._id] = section;
            return acc;
          }, {});
          setSectionsObj(obj);
          setSectionName(resp.name);
          setTeacherName(resp.teacherShow);
          setStudentIdRequired(resp.student_id_required);
          setStudentEmailRequired(resp.student_email_required);
          setSelectedClass(resp._id);
          setShowSectionsSelect(false);
        }
      }
    } catch (err) {
      sendSlackLog(
        "error processing response from get_teacher_code (short)",
        { error: err.toString() },
        "Gatsby student registration form - #checkTeacherCode function"
      );
      sendSlackLog(
        "error processing response from get_teacher_code (long)",
        { error: err.toString(), userAgent: navigator.userAgent },
        "Gatsby student registration form - #checkTeacherCode function"
      );
    }
  };

  React.useEffect(() => {
    if (
      (teacherCode && teacherCode.trim().length === 6) ||
      teacherCode.trim().length === 9
    ) {
      try {
        checkTeacherCode();
      } catch (err) {
        const payload = {
          title: "Teachercode fetching error",
          level: "error",
          text: err.toString(),
          context: "Gatsby student registration form",
        };
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        };
        fetch(getDeltaMathAPI() + "/slack_logger", requestOptions);
      }
    } else {
      clearErrors("submit");
      setTeacherName("");
      setShowSectionsSelect(false);
    }
    resetStates();
  }, [teacherCode]);

  React.useEffect(() => {
    if (props.visible === false) {
      resetStates();
    } else if (!reCaptchaLoaded) {
      loadRecaptchaScript();
      setRecaptchaLoaded(true);
    }
  }, [props.visible]);

  const resetStates = () => {
    // setTeacherCode(""); don't reset this
    setTeacherName("");
    setSelectedClass("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setConfirmEmail("");
    setPassword("");
    setConfirmPassword("");
    setStudentId("");
    setStudentIdRequired(false);
    setStudentEmailRequired(false);
    setShowSectionsSelect(false);
    setEmailChecked(false);
    setEmailAvailable(false);
    setInvalidAccountType(false);
    setSectionName("");
    setRegisterWithEmail(false);
    setGoogleAccountData(null);
    setTeacherCode("");
    clearErrors();
  };

  const checkEmail = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        login: email.trim().split(" ").join("").toLowerCase(),
      }),
    };
    let response = await fetch(
      getDeltaMathAPI() + "/student_login_available",
      requestOptions
    );
    let resp = await response.json();
    setEmailChecked(true);
    setEmailAvailable(resp && resp.login_available);
    setInvalidAccountType(resp && resp.invalid_account_type);
    if (resp.invalid_account_type) {
      setError("checkEmail", {
        type: "manual",
        message:
          "The email address you have entered is already associated with a Teacher or Admin account.",
      });
    }
  };

  return (
    <Dialog
      id="student-form-dialog"
      visible={props.visible}
      onRequestClose={props.disable}
      aria-labelledby="dialog-title"
      className="studentFormDialog form-dialog"
    >
      <span className="sr-only" id="dialog-title">
        Course registration dialog
      </span>
      <DialogFooter>
        <VscChromeClose
          className="dialog-close1"
          id="dialog-close"
          onClick={props.disable}
        />
      </DialogFooter>
      <DialogContent className="student-form-content">
        <Card
          className="modalTeamCard"
          id="student-form-dialog-toggle"
          onClick={props.enable}
        >
          <CardHeader className="teamCard-text">
            <h4 className="rmd-card__title teamCard-text-name">
              Course Registration
            </h4>
          </CardHeader>
          <CardContent>
            {submitted ? (
              showAccountSuccess
            ) : (
              <form
                className="popup-form createStudentAccountForm container"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div id="teacher-code-row-id" className="row">
                  <div className="column">
                    <TextField
                      id="teacher-code-text-field"
                      name="teacherCodeTextField"
                      label="Class Code"
                      defaultValue={parsedCode}
                      ref={register({
                        required:
                          "Please enter the class code provided to you.",
                      })}
                      onChange={(e) => {
                        setTeacherCode(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      aria-invalid={errors.teachercode ? "true" : undefined}
                      aria-describedby={
                        errors.teachercode ? "teacher-code-invalid" : undefined
                      }
                    />
                  </div>
                </div>
                {showSectionsSelect ? (
                  <div id="classSelect-row-id" className="row">
                    <div className="classSelectColumn">
                      <Select
                        id="selectSection"
                        theme="outline"
                        value={selectedClass}
                        options={sectionOptions}
                        label="Select a class"
                        onChange={selectSection}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {teacherName === "" ? (
                  <>
                    <p className="infoContainer">
                      A class code is provided by your teacher and gives you
                      free access to their assignments.{" "}
                      {/*An access code gives you full access to the entire library of
                        DeltaMath content and instructional videos (
                        <a className="infoLink" href="#">
                          learn more
                        </a>
                        ). */}
                    </p>
                    {errors.teachercode && (
                      <div className="row error-row">
                        <div
                          className="validation-alert msg-error"
                          id="teacher-code-invalid"
                        >
                          <p>{errors.teachercode.message}</p>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {teacherName !== "" && selectedClass !== "" ? (
                  <>
                    <label className="teacherNameContainer">
                      Teacher Name:&nbsp;
                      <span className="teacherName">{teacherName}</span>
                      {sectionName !== "" ? (
                        <p className="sectionName">
                          Class:&nbsp;
                          <span className="teacherName">{sectionName}</span>
                        </p>
                      ) : (
                        <></>
                      )}
                    </label>

                    <h2 className="createStudentAccountFormSubHeader">
                      Student and Login Information
                    </h2>
                    {!registerWithEmail ? (
                      <>
                        <div className="row registerChoiceRow">
                          <div>
                            <Button
                              className="cancelBtn registerEmailBtn"
                              themeType="outline"
                              onClick={() => {
                                setRegisterWithEmail(true);
                                clearErrors();
                              }}
                            >
                              Register with{" "}
                              {studentEmailRequired ? "Email" : "Username"}
                            </Button>
                          </div>
                          <div className="registrationOr">or</div>
                          <div>
                            <GoogleSSO
                              callBackFunction={handleSignUpWithGoogle}
                              buttonText="signup_with"
                            />
                          </div>
                        </div>
                        {errors.accountType && (
                          <div className="validation-alert msg-error ">
                            <p>{errors.accountType.message}</p>
                          </div>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {registerWithEmail ? (
                      <>
                        <div id="email-row-id" className="row">
                          <div className="column">
                            <TextField
                              id="email-text-field"
                              name="emailTextField"
                              label={
                                studentEmailRequired ? "Email" : "Username"
                              }
                              type={studentEmailRequired ? "email" : "text"}
                              defaultValue={props_email}
                              value={email}
                              disabled={!!accountData || !!googleAccountData}
                              ref={register({
                                required: `${
                                  studentEmailRequired
                                    ? "Email address"
                                    : "Username"
                                } is required.`,
                              })}
                              onChange={(e) => {
                                setEmail(e.target.value);
                                clearErrors("submit");
                              }}
                            />
                          </div>
                          {!emailChecked || !emailAvailable ? (
                            <div className="column">
                              <Button
                                className="cancelBtn checkEmailBtn"
                                themeType="outline"
                                onClick={() => {
                                  // check if email is in use. If so, have student log in, if not, have then provide password and verify password
                                  checkEmail();
                                }}
                                disabled={email === ""}
                              >
                                Check{" "}
                                {studentEmailRequired ? "Email" : "Username"}
                              </Button>
                            </div>
                          ) : (
                            <>
                              {emailAvailable ? (
                                <>
                                  <div className="column">
                                    <TextField
                                      id="confirmEmail-text-field"
                                      name="confirmEmailTextField"
                                      label={
                                        studentEmailRequired
                                          ? "Email (verify)"
                                          : "Username (verify)"
                                      }
                                      type={
                                        studentEmailRequired ? "email" : "text"
                                      }
                                      defaultValue={props_email}
                                      value={verifyEmail}
                                      disabled={
                                        !!accountData || !!googleAccountData
                                      }
                                      ref={register({
                                        required: `Please confirm your ${
                                          studentEmailRequired
                                            ? "email address"
                                            : "username"
                                        }.`,
                                      })}
                                      onChange={(e) => {
                                        setConfirmEmail(e.target.value);
                                        if (validateEmail(email) && validateEmail(e.target.value) && email !== e.target.value) {
                                          setError("submit", {
                                            type: "manual",
                                            message: "Your two email addresses do not match."
                                          });
                                        } else {
                                          clearErrors("submit");
                                        }
                                      }}
                                    />
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {emailChecked && emailAvailable ? (
                      <>
                        <div id="name-row-id" className="row">
                          <div className="column">
                            <TextField
                              id="firstName-text-field"
                              name="firstNameTextField"
                              label="First Name"
                              defaultValue={given_name}
                              value={firstName}
                              ref={register({
                                required: "First name is required.",
                              })}
                              onChange={(e) => {
                                setFirstName(e.target.value);
                                clearErrors("submit");
                              }}
                            />
                          </div>
                          <div className="column">
                            <TextField
                              id="lastName-text-field"
                              name="lastNameTextField"
                              label="Last Name"
                              defaultValue={family_name}
                              value={lastName}
                              ref={register({
                                required: "Last name is required.",
                              })}
                              onChange={(e) => {
                                clearErrors("submit");
                                setLastName(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        {studentIdRequired ? (
                          <div className="row">
                            <div className="column">
                              <TextField
                                id="studentId-text-field"
                                name="studentIdTextField"
                                label="Student ID"
                                type="text"
                                ref={register({
                                  required: "Student ID is required.",
                                })}
                                onChange={(e) => {
                                  setStudentId(e.target.value);
                                  clearErrors("submit");
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {!!accountData || !!googleAccountData ? (
                          <>
                            <div className="googlePasswordHint">
                              Accounts created with "Sign up with Google" do not
                              need a password.
                            </div>
                          </>
                        ) : (
                          <>
                            <div id="password-row-id" className="row">
                              <div className="column">
                                <TextField
                                  id="password-text-field"
                                  name="passwordTextField"
                                  label="Password"
                                  type="password"
                                  ref={register({
                                    required: "Password is required.",
                                  })}
                                  onChange={(e) => {
                                    setPassword(e.target.value);
                                    clearErrors("submit");
                                    setPasswordSecure(
                                      evaluatePassword(e.target.value)
                                    );
                                  }}
                                />
                              </div>
                              <div className="column">
                                <TextField
                                  id="confirmPassword-text-field"
                                  name="confirmPasswordTextField"
                                  label="Password (verify)"
                                  type="password"
                                  ref={register({
                                    required:
                                      "Enter password again to confirm.",
                                  })}
                                  onChange={(e) => {
                                    setConfirmPassword(e.target.value);
                                    clearErrors("submit");
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {emailChecked && !emailAvailable && !invalidAccountType ? (
                      <>
                        <div id="password-row-id" className="row">
                          <div className="column">
                            <TextField
                              id="password-text-field"
                              name="passwordTextField"
                              label="Password"
                              type="password"
                              ref={register({
                                required: "Password is required.",
                              })}
                              onChange={(e) => {
                                setPassword(e.target.value);
                                setPasswordSecure(
                                  evaluatePassword(e.target.value)
                                );
                                clearErrors("submit");
                              }}
                            />
                          </div>
                          <div className="column">
                            {!submittingNow ? (
                              <>
                                <Button
                                  className="form-submit-btn sign-register-btn"
                                  themeType="contained"
                                  type="button"
                                  onClick={() => {
                                    signInAndRegister();
                                  }}
                                  disabled={submittingNow}
                                >
                                  Sign In & Register
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  className="form-submit-btn sign-register-btn disabled"
                                  disabled
                                >
                                  Sign In & Register
                                </Button>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="column">
                          An account already exists for this user. Please sign
                          in to register for this course.
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {!emailChecked || !emailAvailable ? (
                  <>
                    {errors.checkEmail && (
                      <div className="validation-alert msg-error ">
                        <p>{errors.checkEmail.message}</p>
                      </div>
                    )}
                    <div className="row">
                      <Button
                        className="cancelBtn"
                        themeType="outline"
                        onClick={() => {
                          setShowSectionsSelect(false);
                          props.disable();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="termsOfServiceContainer">
                      <p className="infoContainer">
                        By signing up, you are agreeing to the{" "}
                        <a className="infoLink" href="/terms-of-service">
                          Terms of Service
                        </a>
                      </p>
                    </div>
                    <div className="row error-row">
                      {errors.teacherCodeTextField && (
                        <div className="validation-alert msg-error ">
                          <p>{errors.teacherCodeTextField.message}</p>
                        </div>
                      )}
                      {errors.multipleClasses && (
                        <div className="validation-alert msg-error ">
                          <p>{errors.multipleClasses.message}</p>
                        </div>
                      )}
                      {errors.firstNameTextField && (
                        <div className="validation-alert msg-error ">
                          <p>{errors.firstNameTextField.message}</p>
                        </div>
                      )}
                      {errors.lastNameTextField && (
                        <div className="validation-alert msg-error ">
                          <p>{errors.lastNameTextField.message}</p>
                        </div>
                      )}
                      {errors.emailTextField && (
                        <div className="validation-alert msg-error ">
                          <p>{errors.emailTextField.message}</p>
                        </div>
                      )}
                      {errors.confirmEmailTextField && (
                        <div className="validation-alert msg-error ">
                          <p>{errors.confirmEmailTextField.message}</p>
                        </div>
                      )}
                      {errors.studentIdTextField && (
                        <div className="validation-alert msg-error ">
                          <p>{errors.studentIdTextField.message}</p>
                        </div>
                      )}
                      {errors.passwordTextField && (
                        <div className="validation-alert msg-error ">
                          <p>{errors.passwordTextField.message}</p>
                        </div>
                      )}
                      {errors.confirmPasswordTextField && (
                        <div className="validation-alert msg-error ">
                          <p>{errors.confirmPasswordTextField.message}</p>
                        </div>
                      )}
                      <PasswordRequirementsMessage 
                        password={password} 
                        confirmPassword={confirmPassword} 
                        passwordSecure={passwordSecure} 
                      />                     
                      {errors &&
                        errors.submit &&
                        showSubmitError(errors.submit)}
                      {errors &&
                        errors.submit2 &&
                        showSubmitError(errors.submit2)}
                    </div>

                    <div className="row submit-row">
                      {!submittingNow ? (
                        <>
                          <Button
                            className="form-submit-btn"
                            themeType="contained"
                            type="submit"
                            disabled={
                              !googleAccountData && (
                                submittingNow ||
                                password.length < MIN_PASSWORD_LENGTH ||
                                !passwordSecure ||
                                password !== confirmPassword
                              )
                            }
                          >
                            Create Account
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button className="form-submit-btn disabled" disabled>
                            Create Account
                          </Button>
                        </>
                      )}
                      <Button
                        className="cancelBtn"
                        themeType="outline"
                        type="submit"
                        onClick={() => {
                          setShowSectionsSelect(false);
                          props.disable();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </>
                )}
              </form>
            )}
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
}

export default CreateStudentForm;
