import React from "react";
import { TextField, Button } from "react-md";
import { Dialog, DialogContent, DialogFooter } from "@react-md/dialog";
import { VscChromeClose } from "@react-icons/all-files/vsc/VscChromeClose";
import { Card, CardHeader, CardTitle, CardContent } from "@react-md/card";
import { useForm } from "react-hook-form";
import { navigate } from "gatsby";
import Parser from "html-react-parser";
import { evaluatePassword, getDeltaMathAPI } from "../../utilities/utilities";
import PasswordRequirementsMessage from "../PasswordRequirementsMessage";
const md5 = require("md5");

export const MIN_PASSWORD_LENGTH = 10;

export default function ResetPasswordForm(props) {
  const { token } = props;
  const [submitted, setSubmitted] = React.useState(false);
  const { register, handleSubmit, errors, setError, reset } = useForm();

  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [meetsCriteria, setMeetsCriteria] = React.useState(false);
  const [responseMessage, setResponseMessage] = React.useState("");
  // this response message is relevant when DM staff are helping with password resets
  const [responseMessageDatabase, setResponseMessageDatabase] =
    React.useState("");

  const onSubmit = async (data) => {
    if (newPassword === confirmPassword && newPassword.length >= 8) {
      try {
        // hit endpoint to reset password
        let postDomain = getDeltaMathAPI();
        // let postDomain = 'http://localhost:8001';
        const md5Password = md5("deltamath" + newPassword);
        const data = {
          reset_password: true,
          // token: encodeURIComponent(`"id":${userId},"token":"${token}","teacher":${teacher}`),
          // token: encodeURIComponent(`{"id":${userId},"token":"${token}","teacher":${teacher}}`),
          token,
          new_password: md5Password,
          meets_criteria: meetsCriteria,
        };
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        fetch(postDomain + "/password_reset", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data.message) {
              setResponseMessage(data.message);
            } else {
              setResponseMessage("");
              if (data.message_database)
                setResponseMessageDatabase(data.message_database);
              setSubmitted(true);
              reset();
            }
          });
      } catch (error) {
        setSubmitted(false);
        setError("submit", {
          type: "manual",
          message: "Your password could not be updated. Please try again.",
        });
      }
    }
  };

  const showSubmitError = (msg) => {
    return (
      <div className="validation-alert msg-error">
        <p>{msg.message}</p>
      </div>
    );
  };

  const closeModal = () => {
    if (typeof window !== "undefined") navigate("/");
  };

  const showPasswordUpdateSuccess = (
    <div className="msg-confirm">
      <h2 className="subtitle">Success</h2>
      {responseMessageDatabase.length > 0 ? (
        <div>{Parser(responseMessageDatabase)}</div>
      ) : (
        <p>Your password has been updated.</p>
      )}
      <Button
        onClick={() => {
          if (typeof window !== "undefined") navigate("/");
        }}
        className="outline-primary-btn"
        theme="primary"
        themeType="outline"
      >
        Okay
      </Button>
    </div>
  );

  return (
    <Dialog
      id="reset-password-form-dialog"
      visible={props.visible}
      onRequestClose={closeModal}
      aria-labelledby="dialog-title"
      className="passwordFormDialog form-dialog"
    >
      <span className="sr-only" id="dialog-title">
        Reset password form dialog
      </span>
      <DialogFooter>
        <VscChromeClose
          className="dialog-close1"
          id="dialog-close"
          onClick={closeModal}
        />
      </DialogFooter>
      <DialogContent className="password-form-content">
        <Card
          className="modalTeamCard"
          id="password-form-dialog-toggle"
          onClick={props.enable}
        >
          <CardHeader className="teamCard-text">
            <CardTitle className="teamCard-text-name">Reset Password</CardTitle>
          </CardHeader>
          <CardContent>
            {submitted ? (
              showPasswordUpdateSuccess
            ) : (
              <form
                className="update-password-form container"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="row">
                  <div className="column">
                    <TextField
                      id="new-password-text-field"
                      name="newPasswordTextField"
                      label="New Password"
                      type="password"
                      ref={register({
                        required: "New password is required.",
                      })}
                      onChange={(e) => {
                        setMeetsCriteria(evaluatePassword(e.target.value));
                        setNewPassword(e.target.value);
                      }}
                    ></TextField>
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    <TextField
                      id="confirm-password-text-field"
                      name="confirmPasswordTextField"
                      type="password"
                      label="Confirm Password"
                      ref={register({
                        required: "Enter password again to confirm",
                      })}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    ></TextField>
                  </div>
                </div>
                <div className="error-row">
                  <div className="column">
                    <PasswordRequirementsMessage 
                      password={newPassword} 
                      confirmPassword={confirmPassword} 
                      passwordSecure={meetsCriteria} 
                    />                     
                    {errors && errors.submit && showSubmitError(errors.submit)}
                  </div>
                </div>
                <div className="row submit-row">
                  <div className="column">
                    <Button
                      className="form-submit-btn"
                      themeType="contained"
                      type="submit"
                      disabled={
                        newPassword.length < MIN_PASSWORD_LENGTH ||
                        newPassword !== confirmPassword ||
                        !meetsCriteria
                      }
                    >
                      Reset Password
                    </Button>
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    {responseMessage.length > 0 && (
                      <div className="validation-alert msg-error response-message">
                        <p>{responseMessage}</p>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            )}
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
}
