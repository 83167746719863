import React from "react";
import { Button } from "react-md";
import { Dialog, DialogContent } from "@react-md/dialog";
import { Card, CardHeader, CardTitle, CardContent } from "@react-md/card";
import { actuallyLogin } from "../../utilities/utilities";

export const MIN_PASSWORD_LENGTH = 10;

export default function ResetPasswordReminder(props) {
  const { responseJSON } = props;

  const handleResetPassword = () => {
    const url = parseRedirectUrl({ data: responseJSON, isResetPassword: true });
    responseJSON.redirectUrl = url;
    actuallyLogin(responseJSON);
  };
  const handleGoToApp = () => {
    const url = parseRedirectUrl({
      data: responseJSON,
      isResetPassword: false,
    });
    responseJSON.redirectUrl = url;
    actuallyLogin(responseJSON);
  };

  const isItBefore = (desiredDateString) => {
    const nowObject = new Date();
    const desiredDateObject = new Date(desiredDateString);
    return nowObject < desiredDateObject;
  };

  return (
    <Dialog
      id="choose-sign-up-type-dialog"
      visible={props.visible}
      onRequestClose={props.disable}
      aria-labelledby="dialog-title"
      className="choose-sign-up-type-dialog"
    >
      <span className="sr-only" id="dialog-title">
        Password policy notice
      </span>
      <DialogContent className="choose-sign-up-type-form-content">
        <Card
          className="modalTeamCard"
          id="choose-sign-up-type-form-dialog-toggle"
          onClick={props.enable}
        >
          <CardHeader className="teamCard-text">
            <CardTitle className="teamCard-text-name">
              Password policy notice
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="sign-up-type-button-wrapper">
              <div>
                <p>
                  DeltaMath's password requirements have changed. 
                  After April 15, 2024, your password must be at least {MIN_PASSWORD_LENGTH} characters
                  long and contain at least one uppercase letter, one lowercase
                  letter, one number, and one special character.
                </p>
                <Button
                  type="submit"
                  className="sign-up-type-button"
                  theme="primary"
                  themeType="contained"
                  onClick={() => {
                    handleResetPassword();
                  }}
                >
                  Update Your Password
                </Button>
              </div>
              {isItBefore("2024-04-16") && (
                <>
                  <div className="row center-word-or space-bottom">or</div>
                  <Button
                    type="submit"
                    className="sign-up-type-button"
                    themeType="outline"
                    onClick={() => {
                      handleGoToApp();
                    }}
                  >
                    Continue to the Application
                  </Button>
                </>
              )}
            </div>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
}

const parseRedirectUrl = ({ data, isResetPassword }) => {
  let url = "";
  if (
    data.user &&
    data.user.salutation &&
    (!(
      data.admin &&
      data.admin.account_type &&
      data.user.defaultView === "admin"
    ) ||
      isResetPassword)
  ) {
    // teacher
    if (data.user.course_activation_code) url = "teacher/data";
    // else if (resetPassword) url = "teacher/reset-password"
    else url = "teacher";
    if (isResetPassword) url = url + "/info?passwordRedirect=true";
  } else if (data.account_type || (data.admin && data.admin.account_type)) {
    // admin
    let route =
      data.account_type === "district" ||
      data.account_type === "super_district" ||
      data.admin.account_type === "district" ||
      data.admin.account_type === "super_district"
        ? "district/current-admins"
        : "schools";
    url = `admin/${route}`;
  } else {
    url = "student";
    if (isResetPassword) url = url + "/profile?passwordRedirect=true";
  }
  return url;
};
